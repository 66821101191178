import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import queryString from 'query-string'
import sanitizeHtml from '@/lib/sanitize-html/sanitizeHtml'

import { theme } from '@/styles/theme'

import { Layout } from '@/components/layouts/Layout'
import { Title } from '@/components/elements/Title'
import { BreadCrumbs } from '@/components/layouts/Breadcrumbs'
import { Seo } from '@/components/Seo'
import { HoldStatusBadge } from '@/components/elements/HoldStatusBadge'
import ExternalLinkIcon from '@/images/external-link.svg'

import { Campaign } from '@/interfaces/campaign'
import { useCampaign } from '@/hooks/useCampaign'
import { MainWrapper } from '@/styles/common'

type CampaignContext = Omit<Campaign, 'eyecatch'>

export default function Draft({ location }: { location: Location }) {
  const { contentId, draftKey } = queryString.parse(location.search)
  const [campaign, setCampaign] = useState<CampaignContext>()
  const headers = {
    'X-MICROCMS-API-KEY': process.env.GATSBY_MICROCMS_API_KEY || '',
  }

  useEffect(() => {
    fetch(
      `https://su-pay.microcms.io/api/v1/campaign/${contentId}?draftKey=${draftKey}`,
      {
        headers: headers,
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json()
        }
      })
      .then((json) => {
        setCampaign(json)
      })
  }, [])

  if (!campaign) return <></>

  const breadCrumbs = [
    {
      name: 'トップ',
      path: '/',
    },
    {
      name: 'キャンペーン',
      path: '/campaign/',
    },
    {
      name: campaign.title,
    },
  ]

  const { setHoldStatus } = useCampaign()

  const holdStatus = setHoldStatus(campaign.startdate, campaign.enddate)

  return (
    <Layout>
      <Seo
        title={`${campaign.title} - キャンペーン`}
        description={campaign.description}
      />
      <Title as="h1" bg={true}>
        キャンペーン
      </Title>
      <MainWrapper>
        <BreadCrumbs breadCrumbs={breadCrumbs} />
        <TitleWrapper>
          <HoldStatusBadge holdStatus={holdStatus} />
          <p>
            {dayjs(campaign.startdate).format('YYYY/MM/DD(dd)')}〜
            {dayjs(campaign.enddate).format('YYYY/MM/DD(dd)')}
          </p>
          <Title as="h2" color={theme.colors.supayBlue}>
            {campaign.title}
          </Title>
        </TitleWrapper>
        <CampaignBody>
          {campaign.campaignbody.map((campaign, i) => {
            if (campaign.fieldId === 'campaignNormal') {
              return (
                <CampaignNormal
                  key={`${campaign.fieldId}${i}`}
                  dangerouslySetInnerHTML={{
                    __html: `${sanitizeHtml(campaign.campaignNormal)}`,
                  }}
                />
              )
            } else if (campaign.fieldId === 'campaignCaution') {
              return (
                <CampaignCaution key={`${campaign.fieldId}${i}`}>
                  <h3>ご注意</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${sanitizeHtml(campaign.campaignCaution)}`,
                    }}
                  />
                </CampaignCaution>
              )
            } else if (campaign.fieldId === 'campaignHint') {
              return (
                <CampaignHint
                  key={`${campaign.fieldId}${i}`}
                  dangerouslySetInnerHTML={{
                    __html: `${sanitizeHtml(campaign.campaignHint)}`,
                  }}
                />
              )
            } else if (campaign.fieldId === 'campaignReference') {
              return (
                <CampaignReference key={`${campaign.fieldId}${i}`}>
                  <h3>参考</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${sanitizeHtml(campaign.campaignReference)}`,
                    }}
                  />
                </CampaignReference>
              )
            } else if (campaign.fieldId === 'campaignTable') {
              return (
                <CampaignTable
                  key={`${campaign.fieldId}${i}`}
                  dangerouslySetInnerHTML={{
                    __html: `${sanitizeHtml(campaign.campaignTable)}`,
                  }}
                />
              )
            } else {
              return <></>
            }
          })}
        </CampaignBody>
      </MainWrapper>
    </Layout>
  )
}

const TitleWrapper = styled.div`
  margin: 102px 0 70px;

  h2 {
    line-height: 52px;
    text-align: left;
  }

  p:nth-child(2) {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 1047px) {
    margin: 60px 0 40px;

    h2 {
      line-height: 24px;
      font-size: 20px;
    }

    p:nth-child(2) {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
`

const CampaignBody = styled.section`
  ul {
    margin-top: 12px;

    li {
      display: flex;

      &::before {
        content: '・';
        color: ${(props) => props.theme.colors.supayBlue};
        font-weight: bold;
      }
    }
  }

  ol {
    counter-reset: item 0;
    margin-top: 12px;

    li {
      text-indent: -32px;
      margin-left: 32px;

      &::before {
        content: counter(item) '.';
        counter-increment: item;
        color: ${(props) => props.theme.colors.supayBlue};
        font-weight: bold;
        margin-right: 10px;
      }
    }

    li + li {
      margin-top: 8px;
    }
  }

  a {
    color: ${(props) => props.theme.colors.supayBlue};
    text-decoration: underline;

    &[target='_blank']::after {
      content: '';
      display: inline-block;
      width: 22px;
      height: 22px;
      margin-left: 4px;
      vertical-align: text-top;
      background-size: contain;
      background-image: url(${ExternalLinkIcon});
    }
  }

  @media screen and (max-width: 1047px) {
    img {
      width: 100%;
      object-fit: contain;
    }

    ol li {
      text-indent: -20px;
      margin-left: 20px;
      line-height: 18px;

      &::before {
        margin-right: 8px;
      }

      & + li {
        margin-top: 16px;
      }
    }

    ul li {
      line-height: 18px;

      & + li {
        margin-top: 18px;
      }
    }

    a {
      &[target='_blank']::after {
        width: 16px;
        height: 16px;
      }
    }
  }
`

const CampaignNormal = styled.div`
  font-size: 18px;
  line-height: 36px;

  h3 {
    font-size: 27px;
    line-height: 38px;
    margin: 80px 0 50px;
  }

  h4 {
    font-size: 24px;
    line-height: 34px;
    margin: 80px 0 42px;
    color: ${(props) => props.theme.colors.supayBlue};
  }

  h5 {
    font-size: 22px;
    line-height: 1em;
    margin: 80px 0 40px;
    color: ${(props) => props.theme.colors.textColor};
  }

  @media screen and (max-width: 1047px) {
    font-size: 14px;
    line-height: 22px;

    h3 {
      margin: 40px 0 34px;
      font-size: 18px;
      line-height: 22px;
    }

    h4 {
      margin: 40px 0 34px;
      font-size: 16px;
      line-height: 22px;
    }

    h5 {
      margin: 40px 0 30px;
      font-size: 14px;
      line-height: 22px;
    }
  }
`

const CampaignCaution = styled.div`
  margin-top: 60px;
  padding: 22px 26px;
  background-color: rgba(216, 39, 86, 0.1);
  border-radius: 10px;
  font-size: 20px;

  h3 {
    line-height: 36px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1047px) {
    font-size: 14px;
    padding: 14px 22px;
    margin-top: 32px;

    h3,
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
`

const CampaignHint = styled.div`
  margin-top: 70px;
  padding: 22px 26px;
  background-color: rgba(0, 70, 155, 0.1);
  border-radius: 10px;
  font-size: 20px;

  @media screen and (max-width: 1047px) {
    font-size: 14px;
    padding: 14px 22px;
    margin-top: 32px;
  }
`

const CampaignReference = styled.div`
  margin-top: 60px;
  padding: 22px 26px;
  background-color: rgba(0, 70, 155, 0.1);
  border-radius: 10px;
  font-size: 20px;

  @media screen and (max-width: 1047px) {
    margin-top: 32px;
    font-size: 14px;
    padding: 14px 22px;

    h3,
    ul li {
      font-size: 14px;
      line-height: 20px;
    }
  }
`

const CampaignTable = styled.div`
  margin-top: 60px;

  table,
  th,
  td {
    border: 2px solid rgba(0, 70, 155, 0.1);
    border-collapse: collapse;
  }

  table {
    width: 100%;
  }

  th {
    background-color: rgba(0, 70, 155, 0.1);
    padding: 20px 0;
  }

  td {
    padding: 20px 0;
    text-align: center;
    word-break: break-all;
  }

  @media screen and (max-width: 1047px) {
    font-size: 14px;
    margin-top: 40px;

    th,
    td {
      padding: 10px 2px;
      line-height: 24px;
    }
  }
`
